import { DOMSerializer, Fragment, Node, Schema } from 'prosemirror-model';
import { AddSupplementaryFileComponent } from '../dialogs/supplementary-files/add-supplementary-file/add-supplementary-file.component';
import { schema } from '../utils/Schema';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AddTableDialogComponent } from '../dialogs/citable-tables-dialog/add-table-dialog/add-table-dialog.component';
import { ServiceShare } from './service-share.service';
import { getHtmlFromFragment } from '../utils/prosemirrorHelpers';
import { uuidv4 } from 'lib0/random';
import { AddFigureDialogV2Component } from '../dialogs/figures-dialog/add-figure-dialog-v2/add-figure-dialog-v2.component';
import { AddEndNoteComponent } from '../dialogs/end-notes/add-end-note/add-end-note.component';
import { EditorView } from 'prosemirror-view';

export enum CitationsHTMLNodeNames {}
// Add elements here if needed

export enum ElementsTypes {
  figure = 'figure',
  table = 'table',
  supplementaryFile = 'supplementary-file',
  endNote = 'end-note',
}

export enum ElementsTypeToCitationMap {
  figure = 'citation',
  table = 'table_citation',
  supplementaryFile = 'supplementary_file_citation',
  endNote = 'end_note_citation',
}

// Means that views of this type of element should stay only in the end editor
export enum CitableElementWithStaticViews {
  supplementaryFileCitation = 'supplementary_file_citation',
  endNoteCitation = 'end_note_citation',
}

export enum ElementsContainersPMNodesNames {
  figuresNodesContainer = 'figures_nodes_container',
  tablesNodesContainer = 'tables_nodes_container',
  supplementaryFilesNodesContainer = 'supplementary_files_nodes_container',
  endNotesNodesContainer = 'end_notes_nodes_container',
}

export interface SupplementaryFile {
  title: string;
  authors: string;
  data_type: string;
  brief_description: string;
  supplementary_file_number: number;
  supplementary_file_ID: string;
  url: string;
}

export interface EndNote {
  endNote: string;
  end_note_number: number;
  end_note_ID: string;
  trackChangeId?: string;
}

/**0
: 
elId
: 
"2896b7ea-7e1a-406f-b09d-2288444bba43"
type
: 
"table" */
export interface DisplayedViews {
  elId: string;
  type: string;
}

export interface Citations {
  [key: string]: {
    // section keys
    [key: string]: {
      // citations in section
      displayedViewsHere: DisplayedViews[];
      citedElementsIDs: string[]; // cited elements ids of the type that this citation is
      position: number;
      citationType: string;
    };
  };
}

export interface Table {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  jats_id?: string;
  tableID: string;
  tableNumber: number;
  tablePlace: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  viewed_by_citat: string;
  clientID: number;
  header: string | Element;
  tableContent: string;
  tableFooter: string | Element;
  isNew: boolean;
  editMode?: boolean;
}

export interface FigureComponent {
  description: string;
  url: string;
  componentType: 'image' | 'video' | 'other';
  thumbnail: string;
  pdfImgOrigin: string;
  id: string;
  pdfImgResized: string;
}

export interface Figure {
  jats_id?: string;
  description: string | HTMLTableCaptionElement;
  figureID: string;
  figureNumber: number;
  figurePlace: string;
  viewed_by_citat: string;
  clientID: number;
  isNew: boolean;
  components?: FigureComponent[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canvasData: any;
}

export interface OlderVersionCitableElements {
  table: Table;
  figure: Figure;
}

export enum ModalTemplates {
  Tables = 'TablesModalTemplate',
  SupplementaryFiles = 'SupplementaryFilesModalTemplate',
  Footnotes = 'FootnotesModalTemplate',
  Figures = 'FiguresModalTemplate',
}

export interface ElementsNumbersObj {
  figure: string[];
  table: string[];
  supplementaryFile: string[];
  endNote: string[];
}

export interface ElementsObjects {
  figure: { [key: string]: Figure };
  table: { [key: string]: Table };
  supplementaryFile: { [key: string]: SupplementaryFile };
  endNote: { [key: string]: EndNote };
}

export interface ElementHtmlEntry {
  html: string;
}

export interface ElementHtmlData {
  [uuid: string]: ElementHtmlEntry;
}

export interface ElementsHtmlStructure {
  figure: ElementHtmlData;
  table: ElementHtmlData;
  supplementaryFile: ElementHtmlData;
  endNote: ElementHtmlData;
}

export const citationsPMNodeNames: string[] = Object.values(ElementsTypeToCitationMap);

export const elementsTypes: string[] = Object.values(ElementsTypes);

export const citableElementWithStaticViews: string[] = Object.values(CitableElementWithStaticViews);

export const elementsContainersPMNodesNames: string[] = Object.values(
  ElementsContainersPMNodesNames
);

export const citationElementMap = {
  supplementary_file_citation: {
    htmlTag: 'supplementary-file-citation',
    type: 'supplementaryFile',
    yjsMap: 'supplementaryFilesMap',
    elementsObj: 'supplementaryFiles',
    elementNumberProp: 'supplementary_file_number',
    elementNumbersObj: 'supplementaryFilesNumbers',
    templatesObj: 'supplementaryFilesTemplates',
    containerNodeName: 'supplementary_files_nodes_container',
    contextMenuTxt: 'supplementary file',
    contextMenuBoxWidth: '230px',
    contextMenuArrowPosition: {
      topright: { left: '208px' },
    },
    editModal: AddSupplementaryFileComponent,
    singleElTxt: ' Suppl. material ',
    multipleElTxt: ' Suppl. materials ',
    deletedElTxt: ' Cited item deleted ',
    getElsStrings: function (elementData) {
      let elStrings = '';
      elStrings = elStrings.concat(elementData.brief_description);
      return elStrings;
    },
    setSectionData: function () {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getElFormIOSubmission: function (elementData, citatID) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const serializedSupplementaryFileToFormIOsubmission: any = {};
      serializedSupplementaryFileToFormIOsubmission.supplementaryFileTitle = elementData.title;
      serializedSupplementaryFileToFormIOsubmission.supplementaryFileAuthors = elementData.authors;
      serializedSupplementaryFileToFormIOsubmission.supplementaryFileDataType =
        elementData.data_type;
      serializedSupplementaryFileToFormIOsubmission.supplementaryFileBriefDescription =
        elementData.brief_description;
      serializedSupplementaryFileToFormIOsubmission.supplementaryFileURL = elementData.url;
      serializedSupplementaryFileToFormIOsubmission.supplementary_file_ID =
        elementData.supplementary_file_ID;
      serializedSupplementaryFileToFormIOsubmission.supplementary_file_number =
        elementData.supplementary_file_number;
      return serializedSupplementaryFileToFormIOsubmission;
    },
    getEndEditorPrefixNodes: function (): Node[] {
      return [
        schema.nodes.heading.create(
          { contenteditableNode: false },
          schema.nodes.paragraph.create(
            { contenteditableNode: false },
            schema.text('Supplementary materials')
          )
        ),
      ];
    },
    buildElementFormGroup: function (supplementaryFiles: SupplementaryFile[]): UntypedFormGroup {
      const supplementaryFileFormGroup = new UntypedFormGroup({});
      supplementaryFiles.forEach((supplementaryFile: SupplementaryFile) => {
        const supplementaryFileTitle = new UntypedFormControl(supplementaryFile.title);
        const supplementaryFileAuthors = new UntypedFormControl(supplementaryFile.authors);
        const supplementaryFileDataType = new UntypedFormControl(supplementaryFile.data_type);
        const supplementaryFileBriefDescription = new UntypedFormControl(
          supplementaryFile.brief_description
        );
        const supplementaryFileURL = new UntypedFormControl(supplementaryFile.url);
        supplementaryFileFormGroup.addControl(
          `supplementaryFileTitle${supplementaryFile.supplementary_file_number}`,
          supplementaryFileTitle
        );
        supplementaryFileFormGroup.addControl(
          `supplementaryFileAuthors${supplementaryFile.supplementary_file_number}`,
          supplementaryFileAuthors
        );
        supplementaryFileFormGroup.addControl(
          `supplementaryFileDataType${supplementaryFile.supplementary_file_number}`,
          supplementaryFileDataType
        );
        supplementaryFileFormGroup.addControl(
          `supplementaryFileBriefDescription${supplementaryFile.supplementary_file_number}`,
          supplementaryFileBriefDescription
        );
        supplementaryFileFormGroup.addControl(
          `supplementaryFileURL${supplementaryFile.supplementary_file_number}`,
          supplementaryFileURL
        );
      });
      return supplementaryFileFormGroup;
    },
  },
  table_citation: {
    htmlTag: 'table-citation',
    type: 'table',
    yjsMap: 'tablesMap',
    elementsObj: 'ArticleTables',
    elementNumberProp: 'tableNumber',
    elementNumbersObj: 'ArticleTablesNumbers',
    templatesObj: 'tablesTemplates',
    containerNodeName: 'tables_nodes_container',
    contextMenuBoxWidth: '160px',
    contextMenuArrowPosition: {
      topright: { left: '143px' },
    },
    singleElTxt: ' Table ',
    contextMenuTxt: 'tables',
    multipleElTxt: ' Tables ',
    editModal: AddTableDialogComponent,
    deletedElTxt: ' Cited item deleted ',
    getElsStrings: function (elementData) {
      let elStrings = '';
      elStrings = elStrings.concat(elementData.header);
      elStrings = elStrings.concat(elementData.content);
      elStrings = elStrings.concat(elementData.footer);
      return elStrings;
    },
    setSectionData: function (elementData, sectionId, citeId) {
      elementData.viewed_by_citat = citeId;
      elementData.tablePlace = sectionId;
    },
    getElFormIOSubmission: function (elementData, citatID, serviceShare: ServiceShare) {
      const editor = serviceShare.ProsemirrorEditorsService.editorContainers[elementData.tableID];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const serializedTableToFormIOsubmission: any = {};

      //@ts-expect-error: doc.content may have an unknown structure at runtime
      if (editor && editor.editorView.state.doc.content?.content?.[0]) {
        const DOMPMSerializer = DOMSerializer.fromSchema(schema);

        //@ts-expect-error: doc.content may have an unknown structure at runtime
        const tableNode = editor.editorView.state.doc.content.content[0];
        serializedTableToFormIOsubmission.tableFooter = '';
        const getDataFromNode = (node: Node) => {
          node.content.forEach((node1) => {
            if (node1.type.name == 'table_header_container') {
              node1.content.forEach((node2) => {
                if (node2.type.name == 'table_description') {
                  serializedTableToFormIOsubmission.tableHeader = getHtmlFromFragment(
                    node2.content,
                    DOMPMSerializer
                  );
                }
              });
            } else if (node1.type.name == 'table_footer_container') {
              node1.content.forEach((node2) => {
                if (node2.type.name == 'table_description') {
                  serializedTableToFormIOsubmission.tableFooter = getHtmlFromFragment(
                    node2.content,
                    DOMPMSerializer
                  );
                }
              });
            } else if (node1.type.name == 'table_content') {
              const tableContentHtml = getHtmlFromFragment(node1.content, DOMPMSerializer);
              serializedTableToFormIOsubmission.tableContent = tableContentHtml;
            } else {
              getDataFromNode(node1);
            }
          });
        };
        getDataFromNode(tableNode);

        serializedTableToFormIOsubmission.tableID = elementData.tableID;
        serializedTableToFormIOsubmission.tableNumber = elementData.tableNumber;
        serializedTableToFormIOsubmission.viewed_by_citat = citatID;
        serializedTableToFormIOsubmission.clientID = elementData.clientID;
        return serializedTableToFormIOsubmission;
      } else {
        serializedTableToFormIOsubmission.tableContent =
          elementData.content || elementData.tableContent;
        serializedTableToFormIOsubmission.tableHeader =
          elementData.header || elementData.tableHeader;
        serializedTableToFormIOsubmission.tableFooter =
          elementData.footer || elementData.tableFooter;
        serializedTableToFormIOsubmission.tableID = elementData.tableID;
        serializedTableToFormIOsubmission.tableNumber = elementData.tableNumber;
        serializedTableToFormIOsubmission.viewed_by_citat = citatID;
        serializedTableToFormIOsubmission.clientID = elementData.clientID;
        return serializedTableToFormIOsubmission;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEndEditorPrefixNodes: function (labelState: any): Node[] {
      const marks = [];
      if (labelState) {
        const [username, userColor, user, markName] = labelState.split('&');

        marks.push(
          schema.marks[markName].create({
            user,
            username,
            style: ` `,
            id: uuidv4(),
            userColor,
            userContrastColor: '#000',
            color: userColor,
          })
        );
      }
      return [
        schema.nodes.heading.create(
          {
            contenteditableNode: false,
            style: labelState ? 'background-color: ' + labelState : '',
          },
          schema.nodes.paragraph.create(
            { contenteditableNode: false },
            schema.text('Tables', marks)
          )
        ),
      ];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buildElementFormGroup: function (submision: any): UntypedFormGroup {
      const tableFormGroup = new UntypedFormGroup({});
      const tabCont = new UntypedFormControl(
        submision.editorTableContent ? submision.editorTableContent : submision.tableContent
      );
      const tabHead = new UntypedFormControl(submision.tableHeader);
      const tabFoot = new UntypedFormControl(submision.tableFooter);
      tableFormGroup.addControl('tableContent', tabCont);
      tableFormGroup.addControl('tableHeader', tabHead);
      tableFormGroup.addControl('tableFooter', tabFoot);
      return tableFormGroup;
    },
  },
  citation: {
    htmlTag: 'citation',
    type: 'figure',
    containerNodeName: 'figures_nodes_container',
    yjsMap: 'figuresMap',
    elementsObj: 'ArticleFigures',
    elementNumberProp: 'figureNumber',
    elementNumbersObj: 'ArticleFiguresNumbers',
    templatesObj: 'figuresTemplates',
    contextMenuTxt: 'figures',
    contextMenuArrowPosition: {
      topright: { left: '143px' },
    },
    editModal: AddFigureDialogV2Component,
    contextMenuBoxWidth: '160px',
    singleElTxt: ' Fig ',
    multipleElTxt: ' Figs ',
    deletedElTxt: ' Cited item deleted ',
    getElsStrings: function (elementData) {
      let elStrings = '';
      elStrings = elStrings.concat(elementData.description);
      elementData.components.forEach((comp) => {
        elStrings = elStrings.concat(comp.description);
      });
      return elStrings;
    },
    setSectionData: function (elementData, sectionId, citeId) {
      elementData.viewed_by_citat = citeId;
      elementData.figurePlace = sectionId;
    },
    getElFormIOSubmission: function (elementData, citatID) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const serializedFigureToFormIOsubmission: any = {};
      serializedFigureToFormIOsubmission.figureComponents = elementData.components.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (prev: any[], curr: any) => {
          return prev.concat([{ container: curr }]);
        },
        []
      );
      serializedFigureToFormIOsubmission.figureDescription = elementData.description;
      serializedFigureToFormIOsubmission.figureID = elementData.figureID;
      serializedFigureToFormIOsubmission.figureNumber = elementData.figureNumber;
      serializedFigureToFormIOsubmission.viewed_by_citat = citatID;
      serializedFigureToFormIOsubmission.figurePlace = citatID;
      serializedFigureToFormIOsubmission.nOfColumns = elementData.canvasData.nOfColumns;
      serializedFigureToFormIOsubmission.clientID = elementData.clientID;
      return serializedFigureToFormIOsubmission;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEndEditorPrefixNodes: function (labelState: any): Node[] {
      const marks = [];
      if (labelState) {
        const [username, userColor, user, markName] = labelState.split('&');

        marks.push(
          schema.marks[markName].create({
            user,
            username,
            style: ` `,
            id: uuidv4(),
            userColor,
            userContrastColor: '#000',
            color: userColor,
          })
        );
      }
      return [
        schema.nodes.heading.create(
          { contenteditableNode: false, ychange: 'asd' },
          schema.nodes.paragraph.create(
            { contenteditableNode: false },
            schema.text('Figures', marks)
          )
        ),
      ];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buildElementFormGroup: function (submision: any): UntypedFormGroup {
      const figureFormGroup = new UntypedFormGroup({});
      const figDesc = new UntypedFormControl(submision.figureDescription);
      const formComponents: UntypedFormGroup[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      submision.figureComponents.forEach((comp: any) => {
        const compFormGroup = new UntypedFormGroup({});
        const compDescription = new UntypedFormControl(comp.container.description);
        compFormGroup.addControl('figureComponentDescription', compDescription);
        formComponents.push(compFormGroup);
      });
      const figComponentArray = new UntypedFormArray(formComponents);
      figureFormGroup.addControl('figureDescription', figDesc);
      figureFormGroup.addControl('figureComponents', figComponentArray);
      return figureFormGroup;
    },
  },
  end_note_citation: {
    htmlTag: 'end-note-citation',
    type: 'endNote',
    yjsMap: 'endNotesMap',
    elementsObj: 'endNotes',
    contextMenuArrowPosition: {
      topright: { left: '162px' },
    },
    editModal: AddEndNoteComponent,
    elementNumberProp: 'end_note_number',
    elementNumbersObj: 'endNotesNumbers',
    templatesObj: 'endNotesTemplates',
    elementCitations: 'endNotesCitations',
    contextMenuTxt: 'end notes',
    contextMenuBoxWidth: '180px',
    containerNodeName: 'end_notes_nodes_container',
    singleElTxt: '*',
    multipleElTxt: '*',
    deletedElTxt: ' Cited item deleted ',
    getElsStrings: function (elementData: EndNote) {
      let elStrings = '';
      elStrings = elStrings.concat(elementData.endNote);
      return elStrings;
    },
    getCitationNode: function (
      citedEls: string[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attrs: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      citatedElements: any,
      edView: EditorView
    ) {
      const schema = edView.state.schema as Schema;
      const citatMark = schema.mark('end_note_citation', {
        ...attrs,
        citated_elements: citatedElements,
        nonexistingtable: 'false',
      });
      const newNodePrefix = schema.text('*', [citatMark]) as Node;
      const newNodeCitedEls = schema.text(citedEls.join(', '), [
        citatMark /* ,superscriptMark */,
      ]) as Node;

      return Fragment.from([newNodePrefix, newNodeCitedEls]);
    },
    setSectionData: function () {},
    getElFormIOSubmission: function (elementData: EndNote) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const serializedEndNoteToFormIOsubmission: any = {};
      serializedEndNoteToFormIOsubmission.endNote = elementData.endNote;
      serializedEndNoteToFormIOsubmission.end_note_ID = elementData.end_note_ID;
      serializedEndNoteToFormIOsubmission.end_note_number = elementData.end_note_number;
      return serializedEndNoteToFormIOsubmission;
    },
    getEndEditorPrefixNodes: function (): Node[] {
      return [
        schema.nodes.heading.create(
          { contenteditableNode: false },
          schema.nodes.paragraph.create({ contenteditableNode: false }, schema.text('Endnotes'))
        ),
      ];
    },
    buildElementFormGroup: function (endnotes: EndNote[]): UntypedFormGroup {
      const endNoteFormGroup = new UntypedFormGroup({});
      endnotes.forEach((endNote: EndNote) => {
        endNoteFormGroup.addControl(
          `endNote${endNote.end_note_number}`,
          new UntypedFormControl(endNote.endNote)
        );
      });
      return endNoteFormGroup;
    },
  },
};
