import { createReducer, on } from '@ngrx/store';
import * as CommentsActions from './comments.actions';
import { CommentsState } from './models';

export const initialState: CommentsState = {
  filter: { showResolved: false },
};

export const commentsReducer = createReducer(
  initialState,
  on(CommentsActions.setShowResolved, (state, { showResolved }) => ({
    ...state,
    filter: { showResolved },
  }))
);
