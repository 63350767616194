import { ChangeDetectorRef, Injectable, Injector } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Comment, YdocCommentThread } from '../../comment.models';

@Injectable()
export class SharedCommentsService {
  subscription$ = new Subscription();

  allComments: Comment[] = [];

  searching: boolean = false;
  users: string[] = [];
  sortingFormGroup: UntypedFormGroup;

  searchResults: { inydoc: YdocCommentThread; pmmark: Comment }[];

  constructor() {}

  get arrayControls(): UntypedFormControl[] {
    return (this.sortingFormGroup.get('byCreators') as UntypedFormArray)
      .controls as UntypedFormControl[];
  }

  destroy(): void {
    this.subscription$.unsubscribe();
  }
}
