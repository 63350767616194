import { ArticleCollaborator } from '@app/core/models/article.models';
import { authorListData } from '@app/core/services/all-users.service';
import { Node } from 'prosemirror-model';

export interface SectionContent {
  doc?: Node | null;
  originalSectionName: string;
  level: number;
  sectionID: string;
  parsedSecTitle?: string;
  disabled?: boolean;
  subsections?: SectionContent[];
  originalSecID: string;
}

export interface JatsSection {
  title: string;
  section?: Element;
  sectionID?: string;
  initial?: boolean;
}

export interface ImportWizardDialogData {
  sectionsContent: SectionContent[];
  allJatsSections: JatsSection[];
  allCollaborators: ArticleCollaborator[];
  allAuthors: authorListData[];
}

// export interface Section {
//   doc: Node;
//   sectionTitle: string;
//   secID: string;
//   level?: number;
//   parsedSecTitle?: string;
// }

export type RedundantTextMap = {
  [key: string]: RegExp[];
};

export enum JatsModule {
  article = 'Article',
  dashboard = 'Dashboard',
}

export const generalSection = {
  title: {
    label: 'Section',
    name: '[PS] General Section',
    template: 'Section',
    editable: true,
  },
  sectionID: '89993bfb-ad3e-42e0-89de-3828030e4d65',
  edit: {
    active: false,
    main: true,
  },
  add: {
    active: true,
    main: false,
  },
  delete: {
    active: true,
    main: false,
  },
  addSubSection: {
    active: true,
    main: false,
  },
  mode: 'documentMode',
  formIOSchema: {
    edit: false,
    components: [
      {
        label: 'Content',
        placeholder: 'Enter section content',
        description: null,
        tooltip: null,
        autoExpand: false,
        tableView: true,
        customDefaultValue: null,
        calculateValue: null,
        validate: {
          required: true,
          pattern: null,
          custom: null,
          minLength: null,
          maxLength: null,
          minWords: null,
          maxWords: null,
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false,
        },
        errorLabel: null,
        key: 'sectionContent',
        properties: {
          menuType: 'ContentBasicMenu',
          allowedTags: 'OnlyMarksAndContentSchema',
          optional_with_subsections: 'true',
        },
        conditional: {
          eq: null,
          show: null,
          when: null,
        },
        overlay: {
          style: null,
          left: null,
          top: null,
          width: null,
          height: null,
        },
        type: 'textarea',
        input: true,
        refreshOn: null,
        inputMask: null,
        prefix: '',
        customClass: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: true,
        hidden: false,
        clearOnHide: true,
        redrawOn: '',
        modalEdit: false,
        dataGridLabel: false,
        labelPosition: 'top',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        calculateServer: false,
        widget: {
          type: 'input',
        },
        attributes: [],
        validateOn: 'change',
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        addons: [],
        mask: false,
        inputType: 'text',
        inputFormat: 'html',
        displayMask: '',
        spellcheck: true,
        truncateMultipleSpaces: false,
        rows: 3,
        wysiwyg: false,
        editor: '',
        fixedSize: true,
        id: 'eajzasc',
      },
    ],
  },
  pivotId: 4311,
  id: 20,
  allow_compatibility: true,
  compatibility_extended: [
    {
      id: 20,
      name: '[PS] General Section',
      label: 'Section',
      version: 19,
      version_date: '2025-01-13T10:59:10.000000Z',
    },
    {
      id: 38,
      name: 'Taxon',
      label: "{{(data.taxonTitle!=''&&data.taxonTitle)?data.taxonTitle:'Taxon'}}",
      version: 118,
      version_date: '2024-07-17T10:21:05.000000Z',
    },
  ],
  menusAndSchemasDefs: {
    menus: {},
    schemas: {},
  },
  active: true,
  prosemirrorHTMLNodesTempl:
    '<h${level}>\n\t<p contenteditableNode="true" formControlName="sectionTreeTitle" commentable="false">\n\t</p >\n</h${level}>\n\n<form-field  class="set-align-left" formControlName="sectionContent">\n</form-field >',
  children: [],
  type: 'complex',
  sectionIdFromBackend: 20,
  sectionTypeID: 20,
  sectionTypeVersion: 19,
  sectionMeta: {
    main: false,
  },
  originalSectionTemplate: {
    id: 20,
    pivot_id: 4311,
    name: '[PS] General Section',
    label: 'Section',
    label_read_only: false,
    schema: {
      edit: false,
      components: [
        {
          label: 'Content',
          placeholder: 'Enter section content',
          description: null,
          tooltip: null,
          autoExpand: false,
          tableView: true,
          customDefaultValue: null,
          calculateValue: null,
          validate: {
            required: true,
            pattern: null,
            custom: null,
            minLength: null,
            maxLength: null,
            minWords: null,
            maxWords: null,
            customPrivate: false,
            strictDateValidation: false,
            multiple: false,
            unique: false,
          },
          errorLabel: null,
          key: 'sectionContent',
          properties: {
            menuType: 'ContentBasicMenu',
            allowedTags: 'OnlyMarksAndContentSchema',
            optional_with_subsections: 'true',
          },
          conditional: {
            eq: null,
            show: null,
            when: null,
          },
          overlay: {
            style: null,
            left: null,
            top: null,
            width: null,
            height: null,
          },
          type: 'textarea',
          input: true,
          refreshOn: null,
          inputMask: null,
          prefix: '',
          customClass: '',
          suffix: '',
          multiple: false,
          defaultValue: null,
          protected: false,
          unique: false,
          persistent: true,
          hidden: false,
          clearOnHide: true,
          redrawOn: '',
          modalEdit: false,
          dataGridLabel: false,
          labelPosition: 'top',
          hideLabel: false,
          tabindex: '',
          disabled: false,
          autofocus: false,
          dbIndex: false,
          calculateServer: false,
          widget: {
            type: 'input',
          },
          attributes: [],
          validateOn: 'change',
          allowCalculateOverride: false,
          encrypted: false,
          showCharCount: false,
          showWordCount: false,
          allowMultipleMasks: false,
          addons: [],
          mask: false,
          inputType: 'text',
          inputFormat: 'html',
          displayMask: '',
          spellcheck: true,
          truncateMultipleSpaces: false,
          rows: 3,
          wysiwyg: false,
          editor: '',
          fixedSize: true,
          id: 'eajzasc',
        },
      ],
    },
    sections: [],
    template:
      '<h${level}>\n\t<p contenteditableNode="true" formControlName="sectionTreeTitle" commentable="false">\n\t</p >\n</h${level}>\n\n<form-field  class="set-align-left" formControlName="sectionContent">\n</form-field >',
    type: 1,
    version_id: 1517,
    version: 19,
    version_pre_defined: false,
    version_date: '2025-01-13T10:59:10.000000Z',
    complex_section_settings: null,
    settings: {
      main_section: true,
      min_instances: 0,
      max_instances: 1,
      label: 'Results',
    },
    compatibility: {
      allow: {
        all: false,
        values: [20, 38],
      },
      deny: {
        all: true,
        values: [],
      },
    },
    compatibility_extended: [
      {
        id: 20,
        name: '[PS] General Section',
        label: 'Section',
        version: 19,
        version_date: '2025-01-13T10:59:10.000000Z',
      },
      {
        id: 38,
        name: 'Taxon',
        label: "{{(data.taxonTitle!=''&&data.taxonTitle)?data.taxonTitle:'Taxon'}}",
        version: 118,
        version_date: '2024-07-17T10:21:05.000000Z',
      },
    ],
    allow_compatibility: true,
    created_at: '2023-04-06T16:08:02.000000Z',
  },
  customSchema: {
    isCustom: false,
  },
  compatibility: {
    allow: {
      all: false,
      values: [20, 38],
    },
    deny: {
      all: true,
      values: [],
    },
  },
  sectionMenusAndSchemasDefsfromJSONByfieldsTags: {
    sectionContent: {
      menu: 'ContentBasicMenu',
      schema: 'OnlyMarksAndContentSchema',
    },
  },
  level: 2,
};
