import { Injectable, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AllSelection, EditorState, Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { YMap } from 'yjs';
import { Mark, Node } from 'prosemirror-model';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { checkAllEditorsIfMarkOfCommentExists } from './commentMarksHelpers';
import {
  Comment,
  CommentAttributes,
  YdocCommentThread,
  YdocCommentThreadObj,
} from '@app/editor/comments-section/comment.models';
import { EditorLoadingService } from '@app/editor/services/editor-loading/editor-loading.service';
import { CommentsSelectors } from '@app/store/comments';
import { Store } from '@ngrx/store';
import { YdocService } from '@app/editor/services/ydoc.service';

export const commentMarkNames = [
  'comment',
  'overlapComment',
  'overlapComment2',
  'overlapComment3',
  'overlapComment4',
  'overlapComment5',
  'overlapComment6',
  'overlapComment7',
  'overlapComment8',
  'overlapComment9',
  'overlapComment10',
];

export const articlePosOffset = 24;

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  addCommentSubject;
  commentsPlugin: Plugin;
  commentPluginKey: PluginKey;
  storeData: any;
  editorsOuterDiv?: HTMLDivElement;
  commentsObject: any;
  commentsVisibilityChange: Subject<any>;
  addCommentData?: any = {};
  commentAllowdIn?: any = {}; // editor id where comment can be made RN equals ''/undefined if there is no such editor RN
  selectedTextInEditors?: any = {}; // selected text in every editor
  lastSelectedComments: {
    [key: string]: { commentId: string; commentMarkId: string; sectionId: string; pos: number };
  } = {};
  lastCommentSelected: {
    commentId?: string;
    pos?: number;
    sectionId?: string;
    commentMarkId?: string;
  };
  commentsMap?: YMap<YdocCommentThread>;
  lastSelectedCommentSubject: Subject<{
    commentId?: string;
    pos?: number;
    sectionId?: string;
    commentMarkId?: string;
  }> = new Subject();

  shouldScrollComment = false;
  markIdOfScrollComment?: string = undefined;
  commentsInYdoc: YdocCommentThreadObj = {};

  self = this;

  selectedThreadComment = undefined;

  private showResolved: boolean = false;
  resetCommentsService() {
    this.storeData = undefined;
    this.editorsOuterDiv = undefined;
    this.addCommentData = {};
    this.commentAllowdIn = {}; // editor id where comment can be made RN equals ''/undefined if there is no such editor RN
    this.selectedTextInEditors = {};
  }

  ydocCommentsChangeSubject: Subject<any> = new Subject();

  getCommentsFromYdoc(): YdocCommentThreadObj {
    const commObj: YdocCommentThreadObj = {};
    Array.from(this.commentsMap?.keys() || []).forEach((commentid) => {
      const comment = this.commentsMap.get(commentid);
      if (comment) {
        //@ts-ignore
        commObj[commentid] = comment;
      }
    });
    return commObj;
  }

  setYdocCommentsObj() {
    this.commentsInYdoc = this.getCommentsFromYdoc();
    this.serviceShare.YdocService.checkHiddenCommentsAndUsers();
    this.ydocCommentsChangeSubject.next(this.commentsInYdoc);
  }

  addCommentsMapChangeListener() {
    this.commentsMap = this.serviceShare.YdocService.getCommentsMap();
    this.setYdocCommentsObj();
    this.commentsMap.observe((ymapEvent, trnasact) => {
      this.setYdocCommentsObj();
    });
  }

  scrollToCommentMarkAndSelect() {
    const markid = this.markIdOfScrollComment;
    const edCont = this.serviceShare.ProsemirrorEditorsService.editorContainers;

    let commentFound = false;
    let sectionId;
    let start;
    let end;

    Object.keys(edCont).forEach((sectionid) => {
      const edDoc = edCont[sectionid].editorView.state.doc;
      const docSize = edDoc.content.size;
      edDoc.nodesBetween(0, docSize - 1, (node, pos, parent, i) => {
        if (
          node.marks.find(
            (mark) =>
              commentMarkNames.includes(mark.type.name) && mark.attrs.commentmarkid == markid
          ) &&
          !commentFound
        ) {
          commentFound = true;
          sectionId = sectionid;
          start = pos;
          end = pos + node.nodeSize;
        }
      });
    });
    if (commentFound) {
      setTimeout(() => {
        const view = edCont[sectionId].editorView;
        const state = view.state;
        const doc = state.doc;
        view.focus();
        view.dispatch(
          state.tr.setSelection(TextSelection.between(doc.resolve(start), doc.resolve(end)))
        );
        view.dispatch(view.state.tr.scrollIntoView());
      }, 100);
      return true;
    } else {
      return false;
    }
  }

  handleDeletedComments(deleted: any[]) {
    const filteredFromRepeatingMarks: string[] = [];
    deleted.forEach((comAttrs) => {
      const commentId = comAttrs.attrs.id;
      if (!filteredFromRepeatingMarks.includes(commentId)) {
        filteredFromRepeatingMarks.push(commentId);
      }
    });
    const edConts = this.serviceShare.ProsemirrorEditorsService.editorContainers;
    filteredFromRepeatingMarks.forEach((commentId) => {
      if (!checkAllEditorsIfMarkOfCommentExists(edConts, commentId)) {
        this.commentsMap.delete(commentId);
      }
    });
  }

  updateAllComments() {
    this.buildGlobalCommentMap();
  }

  updateTimestamp = 0;
  updateTimeout;
  changeInEditors = () => {
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      this.updateAllComments();
    }, 500);
  };

  addInlineDecoration(state: EditorState, pos: number) {
    const node = state.doc.nodeAt(pos);
    if (!node) return;

    const comment = node.marks.find((mark) => commentMarkNames.includes(mark.type.name));

    if (!comment) return;

    const { showResolved } = this;

    if (comment.attrs.resolved == 'true' && !showResolved) return;

    let from: number;
    let to: number;

    const nodeSize = state.doc.content.size;
    state.doc.nodesBetween(0, nodeSize, (node, pos, parent, i) => {
      const mark2 = node?.marks.find((mark) => mark.type.name == comment.type.name);
      if (mark2 && mark2.attrs.id == comment.attrs.id && !from) {
        from = pos;
      }
      if (mark2 && mark2.attrs.id == comment.attrs.id) {
        to = pos + node.nodeSize;
      }
    });

    return { from, to };
  }

  constructor(
    private serviceShare: ServiceShare,
    private editorLoadingService: EditorLoadingService,
    private store: Store,
    // Added because of the usage in the effects where YdocService is still not available in the DI. Tested to be working properly (addCommentsMapChangeListener is triggered)
    @Optional() private readonly ydocService?: YdocService
  ) {
    // Subscribe to editors loaded event
    this.editorLoadingService.allEditorsLoaded$.subscribe(() => {
      this.buildGlobalCommentMap();
    });
    const self = this;

    this.lastSelectedCommentSubject.subscribe((data) => {
      this.lastCommentSelected.commentId = data.commentId;
      this.lastCommentSelected.pos = data.pos;
      this.lastCommentSelected.sectionId = data.sectionId;
      this.lastCommentSelected.commentMarkId = data.commentMarkId;
    });
    if (this.ydocService.editorIsBuild) {
      this.addCommentsMapChangeListener();
    } else {
      this.ydocService.ydocStateObservable.subscribe(({ event }) => {
        if (event == 'docIsBuild') {
          this.addCommentsMapChangeListener();
        }
      });
    }
    serviceShare.shareSelf('CommentsService', this);
    const addCommentSubject1 = new Subject<any>();
    this.addCommentSubject = addCommentSubject1;
    this.addCommentSubject.subscribe((data) => {
      if (data.type == 'commentData') {
        this.addCommentData = data;
      } else if (data.type == 'commentAllownes') {
        this.commentAllowdIn[data.sectionId] = data.allow;

        this.selectedTextInEditors[data.sectionId] = data.text;
      }
    });

    const commentPluginKey = new PluginKey('commentPlugin');
    this.commentPluginKey = commentPluginKey;

    const lastSelectedComments: {
      [key: string]: { commentId: string; commentMarkId: string; sectionId: string; pos: number };
    } = {};
    const lastCommentSelected: {
      commentId?: string;
      pos?: number;
      sectionId?: string;
      commentMarkId?: string;
    } = {};
    this.lastCommentSelected = lastCommentSelected;
    this.lastSelectedComments = lastSelectedComments;
    const setLastSelectedComment = this.setLastSelectedComment;
    const changeInEditors = this.changeInEditors;
    this.commentsPlugin = new Plugin({
      key: this.commentPluginKey,
      state: {
        init: (_: any, state) => {
          return { sectionName: _.sectionName };
        },
        apply(tr, prev, oldState, newState) {
          const { from, to, empty } = newState.selection;
          let err = false;
          const text = newState.doc.textBetween(from, to);
          let commentableAttr = true;
          let errorMessage = '';
          if (empty || from == to) {
            errorMessage = 'Selection is empty.';
            err = true;
          }
          let selectedAComment = false;

          const sectionContainer =
            serviceShare.ProsemirrorEditorsService.editorContainers[prev.sectionName];
          const view = sectionContainer ? sectionContainer.editorView : undefined;

          if (!(newState.selection instanceof AllSelection) && view && view.hasFocus()) {
            const pos = newState.selection.from;
            const node = newState.doc.nodeAt(newState.selection.from);

            if (node) {
              const comment = node.marks.find((mark) => commentMarkNames.includes(mark.type.name));

              if (comment) {
                if (self.selectedThreadComment) {
                  self.commentInSelection(
                    node.marks.find((mark) => mark.attrs.id === self.selectedThreadComment),
                    pos,
                    prev.sectionName
                  );
                  selectedAComment = true;
                } else {
                  const currUser = self.serviceShare.YdocService.currUser;
                  const collaborators = self.serviceShare.YdocService.collaborators
                    .get('collaborators')
                    .collaborators.filter((c: any) => c.id != currUser.id);
                  let idsThatShouldBeHidden = collaborators
                    .filter(
                      (c: any) =>
                        c.hide_my_comments_from_user?.includes(currUser?.auth_role) ||
                        c.hide_my_comments_from_user?.includes(currUser?.id)
                    )
                    .map((c: any) => c.id);
                  if (self.serviceShare.hasOwnerCommentsPolicy) {
                    idsThatShouldBeHidden = collaborators
                      .map((c: any) => c.id)
                      .filter((id: string) => id != currUser?.id);
                  }
                  const mark = node.marks.find(
                    (mark) =>
                      commentMarkNames.includes(mark.type.name) &&
                      !idsThatShouldBeHidden.includes(mark.attrs.userid)
                  );
                  if (mark) {
                    self.commentInSelection(mark, pos, prev.sectionName);
                    selectedAComment = true;
                  }
                }
              }
            }

            let node1: Node;
            let node2: Node;
            if (node && from !== to) {
              node1 = newState.doc.nodeAt(from + 1);
              node2 = newState.doc.nodeAt(to - 1);
            }

            if (node1 && node2 && from !== to) {
              self.selectedThreadComment = undefined;
              const isFromCurrentUserMark =
                node1.marks
                  .filter((m) => commentMarkNames.includes(m.type.name))
                  .find((m) => m.attrs.userid == self.serviceShare.YdocService.currUser.id) ||
                node2.marks
                  .filter((m) => commentMarkNames.includes(m.type.name))
                  .find((m) => m.attrs.userid == self.serviceShare.YdocService.currUser.id);

              if (
                node1.textContent == node2.textContent &&
                isFromCurrentUserMark &&
                isFromCurrentUserMark.attrs.resolved == 'false'
              ) {
                err = true;
                errorMessage = 'There is a comment here already';
              }
              //   if (commentMarks3.length && commentMarks4.length && commentMarks3[0].attrs.id == commentMarks4[0].attrs.id && isFromCurrentUser && commentMarks3[0].attrs.resolved == "false") {
              //     err = true;
              //     errorMessage = "There is a comment here already";
              //   }
              //   // if((commentMark1 || commentMark3) && (commentMark2 || commentMark4) || (commentMark1 || commentMark2) && (commentMark3 || commentMark4)) {
              //   //   err = true;
              //   //   errorMessage = "There is a comment here already";
              //   // }

              newState.doc.nodesBetween(from, to, (node, pos, parent) => {
                const comMark = node?.marks.find((mark) =>
                  commentMarkNames.includes(mark.type.name)
                );
                if (comMark) {
                  self.commentInSelection(comMark, pos, prev.sectionName);
                  selectedAComment = true;
                }
                if (node?.attrs.commentable == 'false') {
                  commentableAttr = false;
                }
              });
            }
          }

          if (!commentableAttr && !err) {
            errorMessage = "You can't leave a comment there.";
            err = true;
          }

          if (
            !selectedAComment &&
            !(newState.selection instanceof AllSelection) &&
            view &&
            view.hasFocus() &&
            lastCommentSelected.commentId
          ) {
            setLastSelectedComment(undefined, undefined, undefined, undefined);
          }

          if (
            !(
              newState.selection instanceof AllSelection
            ) /* && view.hasFocus() && tr.steps.length > 0 */
          ) {
            changeInEditors();
          }
          const commentdata = {
            type: 'commentAllownes',
            sectionId: prev?.sectionName,
            allow: !err,
            text,
            errorMessage,
            err,
          };
          addCommentSubject1.next(commentdata);

          return { ...prev, commentsStatus: commentdata };
        },
      },
      props: {
        decorations: (state: EditorState) => {
          const pluginState = this.commentPluginKey.getState(state);
          const focusedEditor = this.serviceShare.DetectFocusService.sectionName;
          const currentEditor = pluginState.sectionName;
          const { from } = state.selection;

          if (currentEditor != focusedEditor) return DecorationSet.empty;

          const markInfo = self.addInlineDecoration(state, from);
          if (!markInfo) return DecorationSet.empty;

          return DecorationSet.create(state.doc, [
            Decoration.inline(markInfo.from, markInfo.to, { class: 'active-comment' }),
          ]);
        },
      },
      view: function () {
        return {
          update: (view, prevState) => {
            if (
              JSON.stringify(view.state.doc) == JSON.stringify(prevState.doc) &&
              !view.hasFocus()
            ) {
              return;
            }
            const pluginData = commentPluginKey.getState(view.state);
            const editor = document
              .getElementsByClassName('editor-container')
              .item(0) as HTMLDivElement;
            const commentsStatus = pluginData.commentsStatus;
            attachCommentBtn(editor, view, commentsStatus);
          },
          destroy: () => {},
        };
      },
    });
    const attachCommentBtn = (editor: HTMLDivElement, view: EditorView, commentsStatus: any) => {
      const { empty, from, to } = view.state.selection;
      if (!editor) return;
      const commentBtnDiv = editor
        .getElementsByClassName('commentBtnDiv')
        .item(0) as HTMLDivElement;
      const commentBtn = editor.getElementsByClassName('commentsBtn').item(0) as HTMLButtonElement;
      const editorBtnsWrapper = editor
        .getElementsByClassName('editor_buttons_wrapper')
        .item(0) as HTMLDivElement;

      if (!view.hasFocus() || !commentBtnDiv) {
        return;
      }
      const coordinatesAtFrom = view.coordsAtPos(from);
      const coordinatesAtTo = view.coordsAtPos(to);

      const averageValueTop = (coordinatesAtFrom.top + coordinatesAtTo.top) / 2; // Selected element position
      const editorOffsetTop = editor.getBoundingClientRect().top; // Editor Top offset in DOM
      const editorBtnsHeight = editorBtnsWrapper.offsetHeight; // Editor buttons dynamic height
      // TODO: Get line height of the selected element
      const currentElement = document.elementFromPoint(
        coordinatesAtFrom.right,
        coordinatesAtTo.top
      );

      let editorLineHeight = 0;
      if (currentElement) {
        editorLineHeight = parseInt(window.getComputedStyle(currentElement).lineHeight, 10);
      }
      editorBtnsWrapper.style.display = 'block';
      const top =
        averageValueTop -
        editorOffsetTop +
        editor.scrollTop -
        editorBtnsHeight / 2 +
        editorLineHeight / 2;

      if (top < 0) {
        editorBtnsWrapper.style.top = '0px';
      } else {
        editorBtnsWrapper.style.top = top + 'px';
      }

      editorBtnsWrapper.style.position = 'absolute';

      if (!commentsStatus.allow) {
        commentBtnDiv.style.display = 'none';
        return;
      }
      commentBtnDiv.style.display = 'block';

      if (!commentBtn) return;
      commentBtn?.removeAllListeners!('click');
      const sectionName = commentPluginKey.getState(view.state).sectionName;

      commentBtn.addEventListener('click', () => {
        this.addCommentSubject.next({ type: 'commentData', sectionName, showBox: true });
        this.serviceShare.DetectFocusService.setSelectionDecorationOnLastSelectedEditor();
        setTimeout(() => {
          this.buildGlobalCommentMap();
        }, 30);
      });
    };

    // Subscribe to store changes to keep private property in sync
    this.store
      .select(CommentsSelectors.selectShowResolved)
      .pipe(distinctUntilChanged())
      .subscribe((value: boolean) => {
        this.showResolved = value;
      });
  }

  commentInSelection = (actualMark: Mark, pos: number, sectionName: string) => {
    if (actualMark) {
      if (actualMark.attrs.resolved == 'true' && !this.showResolved) {
        this.setLastSelectedComment(undefined, undefined, undefined, undefined);
        return;
      }
      if (
        this.sameAsLastSelectedComment(
          actualMark.attrs.id,
          pos,
          sectionName,
          actualMark.attrs.commentmarkid
        )
      ) {
        return;
      } else {
        this.setLastSelectedComment(
          actualMark.attrs.id,
          pos,
          sectionName,
          actualMark.attrs.commentmarkid
        );
        this.lastSelectedComments[actualMark.attrs.id] = {
          commentId: actualMark.attrs.id,
          commentMarkId: actualMark.attrs.commentmarkid,
          sectionId: sectionName,
          pos,
        };
      }
    }
    setTimeout(() => {
      this.selectedThreadComment = undefined;
    }, 1000);
  };

  sameAsLastSelectedComment = (
    commentId?: string,
    pos?: number,
    sectionId?: string,
    commentMarkId?: string
  ) => {
    if (
      this.lastCommentSelected.commentId != commentId ||
      this.lastCommentSelected.sectionId != sectionId ||
      this.lastCommentSelected.commentMarkId != commentMarkId ||
      this.lastCommentSelected.pos != pos
    ) {
      return false;
    } else {
      return true;
    }
  };

  setLastSelectedComment = (
    commentId?: string,
    pos?: number,
    sectionId?: string,
    commentMarkId?: string,
    focus?: true
  ) => {
    this.lastSelectedCommentSubject.next({ commentId, pos, sectionId, commentMarkId });
  };

  commentsObj: { [key: string]: Comment } = {};
  commentsChangeSubject: Subject<any> = new Subject();
  shouldCalc = false;

  buildGlobalCommentMap = () => {
    this.commentsObj = {};
    const edCont = this.serviceShare.ProsemirrorEditorsService.editorContainers;
    Object.keys(edCont).forEach((sectionId) => {
      const view = edCont[sectionId].editorView;
      this.getComments(view, sectionId);
    });
    this.commentsChangeSubject.next('comments pos calc for all sections');
  };

  getComments = (view: EditorView, sectionId: string) => {
    const doc = view.state.doc;
    const docSize: number = doc.content.size;
    doc.nodesBetween(0, docSize - 1, (node, pos, parent, index) => {
      const actualMark = node.marks.filter((mark) => commentMarkNames.includes(mark.type.name));

      if (actualMark.length) {
        // should get the top position , the node document position , the section id of this view
        const articleElement = document.getElementById('app-article-element') as HTMLDivElement;
        const articleElementRactangle = articleElement.getBoundingClientRect();
        const domCoords = view.coordsAtPos(pos);
        let markIsLastSelected = false;

        const selComment = this.lastSelectedComments[actualMark[0].attrs.id];
        if (selComment) {
          if (!this.serviceShare.ProsemirrorEditorsService.editorContainers[selComment.sectionId]) {
            this.lastSelectedComments[actualMark[0].attrs.id] = undefined;
          } else if (
            selComment.pos == pos &&
            selComment.commentId == actualMark[0].attrs.id &&
            selComment.commentMarkId == actualMark[0].attrs.commentmarkid &&
            selComment.sectionId == sectionId
          ) {
            markIsLastSelected = true;
          }
        }
        let lastSelected: true | undefined;
        if (
          this.lastCommentSelected.commentId == actualMark[0].attrs.id &&
          this.lastCommentSelected.commentMarkId == actualMark[0].attrs.commentmarkid &&
          this.lastCommentSelected.sectionId == sectionId &&
          this.lastCommentSelected.pos == pos
        ) {
          lastSelected = true;
        }

        if (
          markIsLastSelected ||
          lastSelected ||
          (!(markIsLastSelected || lastSelected) && !this.commentsObj[actualMark[0].attrs.id])
        ) {
          const currUser = this.serviceShare.YdocService.currUser;
          const collaborators = this.serviceShare.YdocService.collaborators
            .get('collaborators')
            .collaborators.filter((c: any) => c.id != currUser.id);
          let idsThatShouldBeHidden = collaborators
            .filter(
              (c: any) =>
                c.hide_my_comments_from_user?.includes(currUser?.auth_role) ||
                c.hide_my_comments_from_user?.includes(currUser?.id)
            )
            .map((c: any) => c.id);
          if (this.serviceShare.hasOwnerCommentsPolicy) {
            idsThatShouldBeHidden = collaborators
              .map((c: any) => c.id)
              .filter((id: string) => id != currUser?.id);
          }
          if (!idsThatShouldBeHidden.includes(actualMark[0].attrs.userid)) {
            const { textContent, pmDocStartPos, pmDocEndPos, resolved } =
              this.getallCommentOccurrences(actualMark[0].attrs.id, view);
            this.commentsObj[actualMark[0].attrs.id] = {
              commentMarkId: actualMark[0].attrs.commentmarkid,
              pmDocStartPos,
              pmDocEndPos,
              section: sectionId,
              domTop: domCoords.top - articleElementRactangle.top - articlePosOffset,
              commentTxt: textContent,
              commentAttrs: actualMark[0].attrs as CommentAttributes,
              selected: markIsLastSelected,
              resolved,
              threadComments: [],
            };
            actualMark.slice(1).forEach((mark) => {
              if (!idsThatShouldBeHidden.includes(mark.attrs.userid)) {
                const { textContent, pmDocStartPos, pmDocEndPos, resolved } =
                  this.getallCommentOccurrences(mark.attrs.id, view);
                if (
                  this.commentsObj[actualMark[0].attrs.id].pmDocStartPos == pmDocStartPos &&
                  this.commentsObj[actualMark[0].attrs.id].pmDocEndPos == pmDocEndPos
                ) {
                  this.commentsObj[actualMark[0].attrs.id].threadComments.push({
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top - articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs as CommentAttributes,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: [],
                  });
                } else {
                  this.commentsObj[mark.attrs.id] = {
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top - articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs as CommentAttributes,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: [],
                  };
                }
              }
            });
          } else {
            let m: Mark;
            actualMark.slice(1).forEach((mark) => {
              if (!idsThatShouldBeHidden.includes(mark.attrs.userid)) {
                const { textContent, pmDocStartPos, pmDocEndPos, resolved } =
                  this.getallCommentOccurrences(mark.attrs.id, view);
                if (
                  m &&
                  this.commentsObj[m.attrs.id].pmDocStartPos == pmDocStartPos &&
                  this.commentsObj[m.attrs.id].pmDocEndPos == pmDocEndPos
                ) {
                  this.commentsObj[m.attrs.id].threadComments.push({
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top - articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs as CommentAttributes,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: [],
                  });
                } else {
                  m = mark;
                  this.commentsObj[mark.attrs.id] = {
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top - articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs as CommentAttributes,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: [],
                  };
                }
              }
            });
          }
        }
      }
    });
  };

  getallCommentOccurrences(commentId: string, view: EditorView) {
    const nodeSize = view.state.doc.content.size;
    let textContent = '';
    let pmDocStartPos: number, pmDocEndPos: number;
    let resolved: string;

    view.state.doc.nodesBetween(0, nodeSize, (node: Node, pos: number) => {
      const actualMark = node.marks.find((mark) => mark.attrs.id == commentId);
      if (actualMark) {
        textContent += node.textContent;
        // position = pos;
        resolved = actualMark.attrs.resolved;
        if (!pmDocStartPos) {
          pmDocStartPos = pos;
          pmDocEndPos = pos + node.nodeSize;
        } else {
          pmDocEndPos += node.nodeSize;
        }
      }
    });

    return { textContent, pmDocStartPos, pmDocEndPos, resolved };
  }

  removeEditorComment(editorId: any) {
    this.commentsObject[editorId] = [];
    this.lastSelectedComments[editorId] = undefined;
  }

  // init() {
  //   this.editorsOuterDiv = document.getElementsByClassName('editor')[0] as HTMLDivElement
  // }

  getCommentsIds(comment: Comment) {
    return [comment.commentAttrs.id, ...comment.threadComments.map((c) => c.commentAttrs.id)].join(
      ' '
    );
  }

  areAllResolved(comment: Comment) {
    return (
      comment.commentAttrs.resolved == 'true' &&
      !comment.threadComments.find((c) => c.commentAttrs.resolved == 'false')
    );
  }

  getPlugin(): Plugin {
    return this.commentsPlugin;
  }
}
