import { Comment, YdocCommentThread } from './../comments-section/comment.models';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { uuidv4 } from 'lib0/random';
import { Attrs, MarkType } from 'prosemirror-model';
import { EditorState, TextSelection, Transaction } from 'prosemirror-state';

import { ProsemirrorEditorsService } from '../services/prosemirror-editor/prosemirror-editors.service';
import { CommentsService, commentMarkNames } from '../utils/commentsService/comments.service';
import { CommentsRenderingService } from './services/comments-rendering/comments-rendering.service';
import { CommentsNavigationService } from './services/comments-navigation/comments-navigation.service';
import { SharedCommentsService } from './services/shared-comments/shared-comments.service';
import { Subject } from 'rxjs';
import { CollaboratorsAutoCompleteComponent } from './collaborators-auto-complete/collaborators-auto-complete.component';
import { Store } from '@ngrx/store';
import { CommentsActions, CommentsSelectors } from '@app/store/comments';

@Component({
  selector: 'app-comments-section',
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.scss'],
  providers: [CommentsRenderingService, CommentsNavigationService, SharedCommentsService],
})
export class CommentsSectionComponent implements AfterViewInit, OnDestroy {
  @ViewChild('input', { read: ElementRef }) commentInput?: ElementRef;
  @ViewChild('addCommentBox', { read: ElementRef }) addCommentBox: ElementRef;
  @ViewChild('commentsInput', { read: ElementRef }) commentsSearchInput?: ElementRef;

  commentInputFormControl = new UntypedFormControl('');

  showSubMenu = false;

  lastArticleScrollPosition = 0;

  showResolved$ = this.store.select(CommentsSelectors.selectShowResolved);

  constructor(
    public commentsService: CommentsService,
    private changeDetectorRef: ChangeDetectorRef,
    public prosemirrorEditorsService: ProsemirrorEditorsService,
    private sharedCommentsService: SharedCommentsService,
    private store: Store,
    private commentsRenderingService: CommentsRenderingService,
    private commentsNavigationService: CommentsNavigationService
  ) {
    this.changeDetectorRef = changeDetectorRef;
    this.commentsNavigationService.setFormGroup();
    this.commentsRenderingService.setInitialCommentsData();
  }

  get commentAllowedIn(): { [key: string]: boolean } {
    return this.commentsRenderingService.commentAllowedIn;
  }

  get selectedTextInEditors(): {
    [key: string]: string;
  } {
    return this.commentsRenderingService.selectedTextInEditors;
  }

  get lastFocusedEditor(): string {
    return this.commentsRenderingService.lastFocusedEditor;
  }

  get sortingFormGroup(): UntypedFormGroup {
    return this.sharedCommentsService.sortingFormGroup;
  }

  get users(): string[] {
    return this.sharedCommentsService.users;
  }

  get arrayControls(): UntypedFormControl[] {
    return this.sharedCommentsService.arrayControls;
  }

  get searchForm(): UntypedFormControl {
    return this.commentsNavigationService.searchForm;
  }

  get searching(): boolean {
    return this.sharedCommentsService.searching;
  }

  get searchIndex(): number {
    return this.commentsNavigationService.searchIndex;
  }

  get searchResults(): {
    inydoc: YdocCommentThread;
    pmmark: Comment;
  }[] {
    return this.sharedCommentsService.searchResults;
  }

  get showAddCommentBox(): boolean {
    return this.commentsRenderingService.showAddCommentBox;
  }

  get errorMessages(): {
    [key: string]: string;
  } {
    return this.commentsRenderingService.errorMessages;
  }

  get shouldSetNewRows(): boolean {
    return this.commentsRenderingService.shouldSetNewRows;
  }

  get allComments(): Comment[] {
    return this.sharedCommentsService.allComments;
  }

  get doneRenderingComments(): Subject<string> {
    return this.commentsRenderingService.doneRenderingComments$;
  }

  get newCommentMarkId(): string {
    return this.commentsRenderingService.newCommentMarkId;
  }

  ngAfterViewInit(): void {
    this.commentsRenderingService.initialRender = true;
    this.commentsNavigationService.setSortingListener(this.commentsSearchInput);
    this.setScrollListener();
    this.commentsRenderingService.setAddedCommentSubscription(this.commentInput);
    this.commentsRenderingService.setCommentChangeSubscription();
    this.commentsService.buildGlobalCommentMap();
  }

  finnishComment(autocomplete: CollaboratorsAutoCompleteComponent): void {
    autocomplete.canFinishComment(this.commentBtnHandler.bind(this), [
      this.commentInput,
      this.commentInputFormControl.value,
    ]);
  }

  toggleMark(
    markType: MarkType,
    attrs: Attrs
  ): (state: EditorState, dispatch: (tr: Transaction) => void) => boolean {
    return (state: EditorState, dispatch: (tr: Transaction) => void) => {
      const { ranges } = state.selection;
      const existingMarks: string[] = [];
      if (dispatch) {
        let has = false;
        let isOverlap = false;
        const tr = state.tr;
        for (let i = 0; !has && i < ranges.length; i++) {
          const { $from, $to } = ranges[i];
          state.doc.nodesBetween($from.pos, $to.pos, (node) => {
            if (node.isText) {
              // Check for the mark within the node's marks
              node.marks.forEach((mark) => {
                if (commentMarkNames.includes(mark.type.name)) {
                  has = true;
                  existingMarks.push(mark.type.name);
                }
              });
            }
          });
        }
        for (let i = 0; i < ranges.length; i++) {
          const { $from, $to } = ranges[i];
          if (has) {
            isOverlap = true;
            const markName = commentMarkNames.find((markName) => !existingMarks.includes(markName));
            const from = $from.pos,
              to = $to.pos;
            tr.addMark(from, to, state.schema.marks[markName].create(attrs));
          } else {
            const from = $from.pos,
              to = $to.pos;
            tr.addMark(from, to, markType.create(attrs));
          }
        }
        dispatch(tr.scrollIntoView());
        return isOverlap;
      }
    };
  }

  commentBtnHandler(input: HTMLInputElement, value: string): void {
    if (value.length == 0) {
      return;
    }

    if (!this.users.includes(this.prosemirrorEditorsService.userInfo.data.name)) {
      this.users.push(this.prosemirrorEditorsService.userInfo.data.name);
      (this.sortingFormGroup.get('byCreators') as UntypedFormArray).push(
        new UntypedFormControl(false)
      );
    }
    const state = this.commentsRenderingService.editorView?.state;
    const dispatch = this.commentsRenderingService.editorView?.dispatch;
    const from = state.selection.from;
    const to = state.selection.to;

    let commentAtSamePlace: Comment;

    this.allComments.forEach((c) => {
      if (c.pmDocStartPos == from && c.pmDocEndPos == to) {
        commentAtSamePlace = c;
      }
    });

    const textContent = state.doc.textBetween(from, to);

    const commentDate = Date.now();
    const commentId = uuidv4();
    const userCommentId = uuidv4();
    const commentmarkid = this.newCommentMarkId;
    const userComment = {
      id: userCommentId,
      commentId,
      comment: value,
      userData: {
        ...this.prosemirrorEditorsService.userInfo.data,
        userColor: this.prosemirrorEditorsService.userInfo.color.userColor,
      },
      date: commentDate,
      pmDocStartPos: from,
      pmDocEndPos: to,
      commentTxt: textContent,
      sectionId: this.commentsRenderingService.addCommentEditorId,
    };

    this.commentsService.commentsMap.set(commentId, {
      initialComment: userComment,
      commentReplies: [],
    });

    const isOverlap = this.toggleMark(state!.schema.marks.comment, {
      id: commentId,
      date: commentDate,
      commentmarkid,
      userid: this.prosemirrorEditorsService.userInfo.data.id,
      username: this.prosemirrorEditorsService.userInfo.data.name,
      userColor: this.prosemirrorEditorsService.userInfo.color.userColor,
      userContrastColor: this.prosemirrorEditorsService.userInfo.color.userContrastColor,
      resolved: 'false',
    })(state!, dispatch);

    const sectionName = this.commentsRenderingService.addCommentEditorId;
    this.commentsService.addCommentSubject!.next({
      type: 'commentData',
      sectionName,
      showBox: false,
    });
    if (!commentAtSamePlace) {
      this.commentsRenderingService.preventRerenderUntilCommentAdd.bool = true;
      this.commentsRenderingService.preventRerenderUntilCommentAdd.id = commentId;
    }

    setTimeout(() => {
      const editorView = this.commentsRenderingService.editorView;
      editorView.focus();

      if (isOverlap) {
        editorView.dispatch(
          editorView.state.tr.setSelection(
            new TextSelection(
              editorView.state.doc.resolve(to - 5),
              editorView.state.doc.resolve(to - 5)
            )
          )
        );
      } else {
        editorView.dispatch(
          editorView.state.tr.setSelection(
            new TextSelection(
              editorView.state.doc.resolve(from),
              editorView.state.doc.resolve(from)
            )
          )
        );
      }
      input.value = '';

      setTimeout(() => {
        const pluginData = this.commentsService.commentPluginKey.getState(
          this.commentsRenderingService.editorView.state
        );
        const sectionName = pluginData.sectionName;
        this.commentsService.buildGlobalCommentMap();

        setTimeout(() => {
          this.commentsService.setLastSelectedComment(
            commentAtSamePlace?.commentAttrs.id || commentId,
            from,
            sectionName,
            commentAtSamePlace?.commentMarkId || commentmarkid,
            true
          );
          if (commentAtSamePlace) {
            this.commentsService.commentsMap.set(commentId, {
              initialComment: userComment,
              commentReplies: [],
            });
          }
        }, 400);
      }, 20);
    }, 20);
  }

  setScrollListener(): void {
    const container = document.getElementsByClassName('comments-wrapper')[0] as HTMLDivElement;
    const articleElement = document.getElementsByClassName('editor-container')[0] as HTMLDivElement;
    const editorsElement = document.getElementById('app-article-element') as HTMLDivElement;
    const commentsContainer = document.getElementsByClassName(
      'all-comments-container'
    )[0] as HTMLElement;
    const spaceElement = document.getElementsByClassName('end-article-spase')[0] as HTMLDivElement;
    articleElement.addEventListener('scroll', () => {
      this.lastArticleScrollPosition = articleElement.scrollTop;
      if (
        this.commentsRenderingService.lastSorted &&
        this.commentsRenderingService.lastSorted.length > 0
      ) {
        const lastElement =
          this.commentsRenderingService.lastSorted[
            this.commentsRenderingService.lastSorted.length - 1
          ];
        const displayedPos =
          this.commentsRenderingService.displayedCommentsPositions[lastElement.commentAttrs.id];
        const elBottom = displayedPos.displayedTop + displayedPos.height;
        const containerH = commentsContainer.getBoundingClientRect().height;
        if (containerH < elBottom) {
          commentsContainer.style.height = elBottom + 150 + 'px';
        }
        const editorH = editorsElement.getBoundingClientRect().height;
        const spaceElementH = spaceElement.getBoundingClientRect().height;
        const actualEditorH = editorH - spaceElementH;
        if (editorH < elBottom) {
          spaceElement.style.height = elBottom + 150 - actualEditorH + 'px';
        } else if (editorH > elBottom + 100 && spaceElementH > 0) {
          const space = elBottom + 150 - actualEditorH < 0 ? 0 : elBottom + 150 - actualEditorH;
          spaceElement.style.height = space + 'px';
        }
      }
      container.scrollTop = articleElement.scrollTop;
    });
    container.scrollTop = articleElement.scrollTop;
  }

  changeParentContainer(event: boolean, commentContainer: HTMLDivElement): void {
    if (event && !commentContainer.classList.contains('selected-comment')) {
      commentContainer.classList.add('selected-comment');
      this.changeDetectorRef.detectChanges();
    } else if (!event && commentContainer.classList.contains('selected-comment')) {
      commentContainer.classList.remove('selected-comment');
      this.changeDetectorRef.detectChanges();
    }
  }

  handleClickOutside(event: Event, autocomplete: CollaboratorsAutoCompleteComponent): void {
    if (event.target['tagName'] !== 'INPUT' && event.target['tagName'] !== 'MAT-ICON') {
      autocomplete.hideResults();
    }
  }

  adjustRows(): void {
    this.commentsRenderingService.shouldSetNewRows = false;
    const textarea: HTMLTextAreaElement = this.commentInput?.nativeElement;
    if (textarea) {
      if (
        textarea.rows != 1 &&
        this.commentsRenderingService.lastAddBoxHeight != textarea.getBoundingClientRect().height
      ) {
        this.commentsRenderingService.lastAddBoxHeight = textarea.getBoundingClientRect().height;
        this.commentsRenderingService.tryMoveItemsUp = true;
        setTimeout(() => {
          this.commentsRenderingService.doneRendering();
        }, 20);
      }
      textarea.rows = 1;

      while (textarea.rows < 20 && textarea.scrollHeight > textarea.clientHeight) {
        textarea.rows += 1;
      }
    }
  }

  selectPreviousComment(): void {
    this.commentsNavigationService.selectPreviousComment();
  }

  selectNextComment(): void {
    this.commentsNavigationService.selectNextComment();
  }

  endSearch(): void {
    this.commentsNavigationService.endSearch();
  }

  cancelBtnHandle(commentInput: ElementRef): void {
    this.commentsRenderingService.cancelBtnHandle(commentInput);
  }

  ngOnDestroy(): void {
    this.sharedCommentsService.destroy();
    const editorContainer = document.getElementsByClassName(
      'editor-container'
    )[0] as HTMLDivElement;
    editorContainer.removeAllListeners('scroll');
    editorContainer.classList.remove('show-resolved');
    (document.getElementsByClassName('comments-wrapper')[0] as HTMLDivElement).removeAllListeners(
      'wheel'
    );
    this.store.dispatch(CommentsActions.setShowResolved({ showResolved: false }));
  }
}
