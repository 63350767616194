import { Plugin, PluginKey } from 'prosemirror-state';
import { AuthService } from '@app/core/services/auth.service';
import { EditorView } from 'prosemirror-view';
import { Injectable, Optional } from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { Store } from '@ngrx/store';
import { CommentsSelectors } from '@app/store/comments';

export const tooltipPluginKey = new PluginKey('tool-tip-plugin');

@Injectable({
  providedIn: 'root',
})
export class TooltipPluginService {
  private readonly tooltipElementClasses = ['insertion', 'deletion', 'comment'];
  private currUserId: string;
  private toolTip: HTMLSpanElement;
  private toolTipArrow: HTMLSpanElement;
  private showResolved: boolean;

  constructor(
    private serviceShare: ServiceShare,
    @Optional() private authService: AuthService,
    private readonly store: Store
  ) {
    console.log('TooltipPluginService');
    this.toolTip = document.createElement('span');
    this.toolTipArrow = document.createElement('span');
    this.initializeUserId();
    this.store.select(CommentsSelectors.selectShowResolved).subscribe((showResolved) => {
      this.showResolved = showResolved;
    });
  }

  public getPlugin(): Plugin {
    return new Plugin({
      key: tooltipPluginKey,
      props: {
        handleDOMEvents: {
          mouseleave: this.removeToolTip.bind(this),
          wheel: this.removeToolTip.bind(this),
          mouseover: this.handleMouseOver.bind(this),
        },
      },
    });
  }

  private initializeUserId(): void {
    this.authService.currentUser$.subscribe((userInfo) => {
      if (userInfo?.id) this.currUserId = userInfo.id;
    });
  }

  private getTargetElement(event: MouseEvent | WheelEvent): HTMLElement | null {
    return event.target as HTMLElement;
  }

  private removeToolTip(view: EditorView, event: MouseEvent | WheelEvent): void {
    const targetElement = this.getTargetElement(event);

    if (
      !(
        targetElement instanceof HTMLSpanElement &&
        this.tooltipElementClasses.some((className) => targetElement.classList.contains(className))
      )
    ) {
      if (this.toolTip.parentNode === document.body) {
        document.body.removeChild(this.toolTip);
      }
    }
  }

  private handleMouseOver(view: EditorView, event: MouseEvent): void {
    const targetElement = this.getTargetElement(event) as HTMLSpanElement;
    const username = targetElement?.getAttribute('data-username');

    if (
      !targetElement ||
      !username ||
      !this.tooltipElementClasses.some((className) => targetElement.classList.contains(className))
    ) {
      this.removeToolTip(view, event);
      return;
    }

    if (targetElement.classList.contains('comment')) {
      const { showResolved } = this;
      const isResolved = targetElement.getAttribute('resolved') === 'true';
      const displayCommentCreator = !isResolved || showResolved;
      if (!displayCommentCreator) {
        return;
      }
    }

    const tooltipData = this.getTooltipData(targetElement);
    this.renderTooltip(tooltipData, targetElement, event);
  }

  private getTooltipData(element: HTMLSpanElement): {
    userColor: string;
    userContrastColor: string;
  } {
    const userId = element.getAttribute('user');
    const userId2 = element.getAttribute('data-userid');
    const userColorAttr = element.getAttribute('usercolor');
    const userContrastColorAttr = element.getAttribute('usercontrastcolor');

    let userColor = userColorAttr || '';
    let userContrastColor = userContrastColorAttr || '';

    if (this.currUserId === userId || userId2 === this.currUserId) {
      userColor = '#00B1B2';
      userContrastColor = 'white';
    }

    return { userColor, userContrastColor };
  }

  private renderTooltip(
    tooltipData: { userColor: string; userContrastColor: string },
    targetElement: HTMLSpanElement,
    event: MouseEvent
  ): void {
    const { userColor, userContrastColor } = tooltipData;
    const rect = targetElement.getBoundingClientRect();
    const userId2 = targetElement.getAttribute('data-userid');
    const username = targetElement.getAttribute('data-username');
    const display =
      this.serviceShare.ProsemirrorEditorsService.previewArticleMode.mode &&
      !this.serviceShare.oldVersion
        ? 'none'
        : 'block';

    this.setTooltipStyles(rect, userColor, userContrastColor, display);
    this.setTooltipArrowStyles(userColor, display);

    this.toolTip.setAttribute('user-id', userId2 || '');
    this.toolTip.innerHTML = username || '';
    this.toolTip.className = 'user-tooltip';

    document.body.appendChild(this.toolTip);
    this.toolTip.appendChild(this.toolTipArrow);
    this.toolTip.style.left = event.clientX - this.toolTip.getBoundingClientRect().width / 2 + 'px';
  }

  private setTooltipStyles(
    rect: DOMRect,
    userColor: string,
    userContrastColor: string,
    display: string
  ): void {
    this.toolTip.setAttribute(
      'style',
      `
      color: ${userContrastColor};
      background-color: ${userColor};
      top: ${rect.top - 27}px;
      padding-right: 3px;
      padding-left: 3px;
      border-radius: 4px;
      position: absolute;
      z-index: 2;
      display: ${display};
    `
    );
  }

  private setTooltipArrowStyles(userColor: string, display: string): void {
    this.toolTipArrow.setAttribute(
      'style',
      `
      width: 0;
      height: 0;
      position: absolute;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      display: ${display};
      margin-right: calc(50% - 7px);
      margin-left: calc(50% - 7px);
      border-top: 7px solid ${userColor};
    `
    );
  }
}
