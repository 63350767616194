import { ElementRef, Injectable, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { combineLatest, interval } from 'rxjs';
import { debounce, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { TextSelection } from 'prosemirror-state';

import { CommentsActions } from '@app/store/comments';
import { CommentsService } from '@app/editor/utils/commentsService/comments.service';
import { Comment, YdocCommentThread } from '../../comment.models';
import { SharedCommentsService } from '../shared-comments/shared-comments.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { CommentsRenderingService } from '../comments-rendering/comments-rendering.service';

@Injectable()
export class CommentsNavigationService {
  searchIndex: number = 0;
  searchForm = new UntypedFormControl('');

  constructor(
    private commentsService: CommentsService,
    private sharedCommentsService: SharedCommentsService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private commentsRenderingService: CommentsRenderingService
  ) {}

  setSortingListener(commentsSearchInput: ElementRef): void {
    this.sharedCommentsService.subscription$.add(
      combineLatest([
        this.sharedCommentsService.sortingFormGroup.valueChanges
          .pipe(startWith({}))
          .pipe(debounce(() => interval(300))),
        this.searchForm.valueChanges.pipe(startWith('')).pipe(debounce(() => interval(500))),
      ])
        .pipe(debounce(() => interval(200)))
        .subscribe(([values, val]) => {
          const comments = Array.from(
            document.getElementsByClassName('comment-container')
          ) as HTMLDivElement[];
          const editorContainer = document.getElementsByClassName(
            'editor-container'
          )[0] as HTMLDivElement;

          if (values.showResolved) {
            editorContainer.classList.add('show-resolved');
          } else {
            editorContainer.classList.remove('show-resolved');
          }

          if (
            values.iAmMentioned ||
            values.byCreators?.find((creator: boolean) => creator == true) ||
            values.showResolved ||
            (val && typeof val == 'string' && val.trim().length > 0)
          ) {
            const searchVal = val?.trim() || '';

            const commentsInYdocMap = this.commentsService.getCommentsFromYdoc();
            const commentsInYdocFiltered: { inydoc: YdocCommentThread; pmmark: Comment }[] = [];
            const sortedComments = this.sharedCommentsService.allComments.sort((c1, c2) => {
              if (c1.domTop != c2.domTop) {
                return c1.domTop - c2.domTop;
              } else {
                return c1.pmDocStartPos - c2.pmDocStartPos;
              }
            });
            sortedComments.forEach((com) => {
              commentsInYdocFiltered.push({
                inydoc: commentsInYdocMap[com.commentAttrs.id],
                pmmark: com,
              });
            });
            let foundComments: {
              inydoc: YdocCommentThread;
              pmmark: Comment;
            }[] = [];

            commentsInYdocFiltered.forEach((data) => {
              let isAdded = false;
              if (
                values.iAmMentioned &&
                (data.inydoc.initialComment.comment.includes(
                  this.prosemirrorEditorsService.userInfo.data.email
                ) ||
                  data.inydoc.commentReplies.find((reply) =>
                    reply.comment.includes(this.prosemirrorEditorsService.userInfo.data.email)
                  ))
              ) {
                foundComments.push(data);
                isAdded = true;
              }

              values.byCreators?.forEach((creator: boolean, i: number) => {
                if (
                  !isAdded &&
                  creator &&
                  this.sharedCommentsService.users[i] == data.inydoc.initialComment.userData.name
                ) {
                  foundComments.push(data);
                  isAdded = true;
                }
              });

              if (
                searchVal &&
                (data.inydoc.initialComment.comment.toLocaleLowerCase().includes(searchVal) ||
                  data.inydoc.initialComment.userData.email
                    .toLocaleLowerCase()
                    .includes(searchVal) ||
                  data.inydoc.initialComment.userData.name
                    .toLocaleLowerCase()
                    .includes(searchVal) ||
                  data.inydoc.commentReplies.reduce((prev, curr) => {
                    return (
                      prev ||
                      curr.comment.toLocaleLowerCase().includes(searchVal) ||
                      curr.userData.email.toLocaleLowerCase().includes(searchVal) ||
                      curr.userData.name.toLocaleLowerCase().includes(searchVal)
                    );
                  }, false)) &&
                !isAdded
              ) {
                foundComments.push(data);
              }
            });

            this.store.dispatch(
              CommentsActions.setShowResolved({ showResolved: values.showResolved })
            );

            if (values.showResolved && foundComments.length == 0) {
              foundComments = commentsInYdocFiltered;
            }

            if (foundComments.length > 0) {
              comments.forEach((com) => {
                const commentData = foundComments.find((c) =>
                  com.classList.contains(c.pmmark.commentAttrs.id)
                );
                if (!commentData) {
                  this.commentsRenderingService.hideComment(com);
                } else {
                  if (com.getAttribute('resolved') == 'false') {
                    this.commentsRenderingService.showComment(com);
                  } else if (
                    (com.getAttribute('resolved') == 'true' && values.showResolved) ||
                    commentData.pmmark.threadComments.find(
                      (c) => c.commentAttrs.resolved == 'false'
                    )
                  ) {
                    this.commentsRenderingService.showComment(com);
                  } else {
                    foundComments = foundComments.filter((comment) =>
                      com.classList.contains(comment.pmmark.commentAttrs.id)
                    );
                    this.commentsRenderingService.hideComment(com);
                  }
                }
              });

              if (foundComments.length > 0) {
                this.selectComment(foundComments[0].pmmark);
                this.searchIndex = 0;
              } else {
                this.searchIndex = -1;
              }

              setTimeout(() => {
                commentsSearchInput?.nativeElement.focus();
              }, 10);

              this.sharedCommentsService.searchResults = foundComments;
              this.sharedCommentsService.searching = true;
            } else {
              comments.forEach((com) => {
                this.commentsRenderingService.hideComment(com);
              });
              this.sharedCommentsService.searchResults = foundComments;
              this.searchIndex = -1;
              this.sharedCommentsService.searching = true;
            }
          } else {
            comments.forEach((com) => {
              if (com.getAttribute('resolved') == 'false') {
                this.commentsRenderingService.showComment(com);
              } else {
                this.commentsRenderingService.hideComment(com);
              }
            });
            this.sharedCommentsService.searching = false;
            this.store.dispatch(CommentsActions.setShowResolved({ showResolved: false }));
          }
          this.commentsService.ydocCommentsChangeSubject.next(false);
          this.changeDetectorRef.detectChanges();
        })
    );
  }

  selectComment(com: Comment): void {
    const actualMark = this.commentsService.commentsObj[com.commentAttrs.id];
    const edView = this.prosemirrorEditorsService.editorContainers[actualMark.section].editorView;
    const st = edView.state;
    const doc = st.doc;
    const tr = st.tr;
    const textSelection = new TextSelection(
      doc.resolve(actualMark.pmDocStartPos),
      doc.resolve(actualMark.pmDocStartPos)
    );
    edView.dispatch(tr.setSelection(textSelection));
    const articleElement = document.getElementsByClassName(
      'main-editor-container'
    )[0] as HTMLDivElement;
    articleElement.scroll({
      top: actualMark.domTop - 300,
      left: 0,
      behavior: 'smooth',
    });
    edView.focus();
  }

  selectPreviousComment(): void {
    if (this.sharedCommentsService.searchResults.length > 0) {
      this.searchIndex--;
      const com = this.sharedCommentsService.searchResults[this.searchIndex];
      this.selectComment(com.pmmark);
    }
  }

  selectNextComment(): void {
    if (this.sharedCommentsService.searchResults.length > 0) {
      this.searchIndex++;
      const com = this.sharedCommentsService.searchResults[this.searchIndex];
      this.selectComment(com.pmmark);
    }
  }

  endSearch(): void {
    this.sharedCommentsService.searching = false;
    this.searchIndex = 0;
    this.sharedCommentsService.searchResults = [];
    this.searchForm.setValue('');
    this.sharedCommentsService.sortingFormGroup.setValue({
      showResolved: false,
      byCreators: this.sharedCommentsService.users.map(() => false),
      iAmMentioned: false,
    });
  }

  setFormGroup(): void {
    this.sharedCommentsService.sortingFormGroup = this.formBuilder.group({
      showResolved: false,
      byCreators: this.formBuilder.array([]),
      iAmMentioned: false,
    });
  }
}
