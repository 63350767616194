import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import * as PModel from 'prosemirror-model';

import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { EditSectionService } from '@app/editor/dialogs/edit-section-dialog/edit-section.service';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { HelperService } from '@app/editor/section/helpers/helper.service';
import { FormBuilderService } from '@app/editor/services/form-builder.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { ArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ImportJatsService } from '../../../importAsJatsXML.service';
import { ImportWizardDialogData, JatsSection, SectionContent } from '../../../jats.models';
import { JatsCitableElementsService } from '../../../jats-citable-elements.service';

@Component({
  selector: 'app-demo-editor',
  templateUrl: './demo-editor.component.html',
  styleUrls: ['./demo-editor.component.scss'],
})
export class DemoEditorComponent implements AfterViewInit {
  @Input() data: ImportWizardDialogData;
  @Input() section: SectionContent;
  @Input() shouldPreserve: boolean;

  @ViewChild('ProsemirrorEditor', { read: ElementRef }) prosemirrorEditor?: ElementRef;

  triggerCustomSecSubmit = new Subject();

  constructor(
    private editSectionService: EditSectionService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private treeService: TreeService,
    private ydocService: YdocService,
    private formBuilderService: FormBuilderService,
    public detectFocusService: DetectFocusService,
    public helperService: HelperService,
    private serviceShare: ServiceShare,
    public importJatsService: ImportJatsService,
    public jatsCitableElementsService: JatsCitableElementsService,
    @Inject(APP_CONFIG) readonly config: AppConfig
  ) {}

  ngAfterViewInit(): void {
    this.processValues(
      [this.data.allJatsSections.find((s) => s.sectionID == this.section.sectionID)].filter(
        (e) => e != undefined
      ),
      this.section.sectionID
    ).then((doc) => {
      this.createEditor(doc);
    });
  }

  findSection(id: string): ArticleSection {
    const section = this.serviceShare.YdocService.articleSectionsMap.get(id);
    return section;
  }

  createEditor(doc: PModel.Node): void {
    this.prosemirrorEditor.nativeElement.innerHTML = '';
    this.jatsCitableElementsService.editors[this.section.sectionID] =
      this.serviceShare.ProsemirrorEditorsService.renderPreviewEditor(
        this.prosemirrorEditor?.nativeElement,
        doc
      );
  }

  private async processValues(values: JatsSection[], sectionID: string): Promise<PModel.Node> {
    // if (
    //   values.length == 0 &&
    //   !this.section.originalSectionName.includes('Title') &&
    //   this.prosemirrorEditorsService.editorContainers[this.section.sectionID]
    // ) {
    //   return this.prosemirrorEditorsService.editorContainers[this.section.sectionID].editorView
    //     .state.doc;
    // }

    const sec = values.map((val) => val?.section).filter((e) => e != undefined);

    // Clean label from html if any
    // const htmlFreeLabel = section.title.label?.match(/<[^>]*>/g)
    //   ? section.title.label.replace(/<[^>]*>/g, '').trim()
    //   : section.title.label;

    // If there is parsed section title from the jats file we use it, if not we use label free from html
    const sectionTitle = this.section.parsedSecTitle; //|| htmlFreeLabel;

    const section = this.ydocService.getSectionByID(this.section.sectionID);

    const doc = await this.importJatsService.parseSectionsFromModal(
      sec,
      section || this.section,
      this.section.level,
      this.shouldPreserve,
      sectionTitle,
      !!values.find((val) => val.initial)
    );
    this.section.doc = doc;

    return doc;
  }
}
